import React from "react";
import "../assets/styles/changeyourlife.css";
import { animated } from "react-spring";
import useBoop from "use-boop";
import { HiChevronDown } from "react-icons/hi";
import {
  scroller,
} from "react-scroll";

export default function ChangeYourLife({ setIsOpenModalForm }) {
  const [style, trigger] = useBoop({ y: 4 });
  return (
    <div className="container_changeyourlife">
      <h1>quer mudar de vida?</h1>
      <p>Baixe agora nossa apresentação</p>
      <button
        onMouseEnter={trigger}
        onClick={() => {
          scroller.scrollTo("form_download_apresentation", {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuint",
          });
        }}
      >
        <span className="text_download_btn_changeyourlife">Fazer download</span>
        <span className="icon_download_btn_changeyourlife">
          <animated.span style={style}>
            <HiChevronDown size={25} />
          </animated.span>
        </span>
      </button>
    </div>
  );
}
