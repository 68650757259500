import React from "react";
import "../assets/styles/footer.css";
import backgroundFooter from "../assets/img/background_footer.png";
import { ImFacebook, ImYoutube2 } from "react-icons/im";
import { GrInstagram, GrLinkedinOption } from "react-icons/gr";
import { IconContext } from "react-icons";
export default function Footer(props) {
  return (
    <div
      className="container_footer"
      style={{ backgroundImage: "url(" + backgroundFooter + ")" }}
    >
      <img src={props.Logo} alt="..." />

      <div className="content_social_icons">
        <button
          onClick={() =>
            window.open(
              "https://www.facebook.com/SuperGeeksBrasil/",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <IconContext.Provider
            value={{ color: "#1875EF", className: "icon_footer" }}
          >
            <ImFacebook size={"25"} />
          </IconContext.Provider>
        </button>
        <button
          onClick={() =>
            window.open(
              "https://www.youtube.com/supergeeksbr",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <IconContext.Provider
            value={{ color: "#FF0000", className: "icon_footer" }}
          >
            <ImYoutube2 size={"25"} />
          </IconContext.Provider>
        </button>
        <button
          onClick={() =>
            window.open(
              "https://br.linkedin.com/company/supergeeks-brasil",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <IconContext.Provider
            value={{ color: "#0A66C2", className: "icon_footer" }}
          >
            <GrLinkedinOption size={"25"} />
          </IconContext.Provider>
        </button>
        <button
          onClick={() =>
            window.open(
              "https://www.instagram.com/SuperGeeksBrasil",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <IconContext.Provider
            value={{ color: "#CB2052", className: "icon_footer" }}
          >
            <GrInstagram size={"25"} />
          </IconContext.Provider>
        </button>
      </div>
      <p>SuperGeeks © Copyright {new Date().getFullYear()}. Todos os direitos reservados.</p>
    </div>
  );
}
