import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import axios from "axios";
import { navigate } from "gatsby";
import Lottie from "react-lottie";
import { Modal } from "react-responsive-modal";
import { BsFillPlayFill } from "react-icons/bs";
import { scroller } from "react-scroll";
import PhoneInput, { isValidPhoneNumber,  formatPhoneNumberIntl } from "react-phone-number-input";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/scale.css';

//styles
import "../assets/styles/landingpage.css";
import "react-responsive-modal/styles.css";

//animations
import LoadFormAnimation from "../../static/8574-loading.json";
import successCheck from "../../static/lf30_editor_vsvrdvkg.json";
import incorrectCheck from "../../static/91879-incorrect.json";

//Images
import imgVideo from "../assets/img/img_video.jpg";
import Exame from "../assets/img/landingpage/exame.png";
import Folha from "../assets/img/landingpage/folha.png";
import G1 from "../assets/img/landingpage/g1.png";
import GNews from "../assets/img/landingpage/gNews.png";
import GrandeEmpresas from "../assets/img/landingpage/grandesemepresas.png";
import Fantastico from "../assets/img/landingpage/fantastico.png";
import R7 from "../assets/img/landingpage/r7.png";
import SBT from "../assets/img/landingpage/SBT2.png";
import guiafranquiasdesucesso from "../assets/img/landingpage/guiafranquiasdesucesso.png";
import Encontro from "../assets/img/landingpage/encontro.png";
import OlharDigital from "../assets/img/landingpage/olhar-digital-header-logo.png";
import Band from "../assets/img/landingpage/band-logo-tv-3.png";


const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function LandingPage({ setIsOpenModalForm }) {
  let [open, setOpen] = useState(false);
  const [dataForm, setDataForm] = useState({
    name: null,
    lastName: null,
    email: null,
    country: "Brazil",
    state: null,
    city: null,
    investment: "50-100k",
  });
  let [statusPhoneWhatsapp, setStatusPhoneWhatsapp] = useState(false);
  let [loadingForm, setLoadingForm] = useState(false);
  let [phoneCode, setPhoneCode] = useState("");


  const disableSubmit = useMemo(() => {


    // Campos que não precisam de validação
    const fieldsNotValidated = ["investment", "country", "state", "city"];

    // Status se todos os campos que não precisam de validação estão preenchidos
    const isValidNotValidated = fieldsNotValidated.every((field) => dataForm[field] !== null && dataForm[field] !== "");

    // Status se o campo de email está preenchido e é válido'
    const isValidEmail = dataForm.email !== null && validateEmail(dataForm.email)

    const isValidName = dataForm.name !== null && dataForm.name !== "" && dataForm.name.length > 2

    const isValidLastName = dataForm.lastName !== null && dataForm.lastName !== "" && dataForm.lastName.length > 2

    const isValidPhone = statusPhoneWhatsapp

    return !(isValidNotValidated && isValidEmail && isValidName && isValidLastName && isValidPhone)


  }, [dataForm, statusPhoneWhatsapp])

  const selectCountryRef = useRef(null)

  const capacityInvestment = useRef([
    {
      label: "De 50 mil à 100 mil",
      value: "50k-100k",
    },
    {
      label: "De 100 mil à 150 mil",
      value: "100k-150k",
    },
    {
      label: "De 150 mil à 200 mil",
      value: "150k-200k",
    },
    {
      label: "De 200 mil à 250 mil",
      value: "200k-250k",
    },
    {
      label: "Acima de 250 mil",
      value: "acima250k",
    },
  ]);

  const defaultOptions = useRef({
    loop: true,
    autoplay: true,
    animationData: LoadFormAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  })

  const defaultOptionsSuccess = useRef({
    loop: false,
    autoplay: true,
    animationData: successCheck,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  })

  const defaultOptionsIncorrect = useRef({
    loop: false,
    autoplay: true,
    animationData: incorrectCheck,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  })

  let imagesListNews = [
    {
      name: "exame",
      img: Exame,
      url: "https://exame.com/pme/conheca-a-escola-brasileira-de-programacao-que-nasceu-no-vale/",
    },
    {
      name: "folha",
      img: Folha,
      url: "https://www1.folha.uol.com.br/mercado/2016/11/1834252-apos-falir-3-vezes-casal-fatura-r-8-mi-ensinando-programacao-para-criancas.shtml",
    },
    {
      name: "guia franquia",
      img: guiafranquiasdesucesso,
      url: "https://guiafranquiasdesucesso.com/noticias/franchise-insider-supergeeks/",
    },
    {
      name: "GNews",
      img: GNews,
      url: "https://www.youtube.com/watch?v=g0gfnbHSL8E",
    },
    {
      name: "GradeEmpresas",
      img: GrandeEmpresas,
      url: "https://revistapegn.globo.com/Banco-de-ideias/Educacao/noticia/2018/01/casal-fatura-r-12-milhoes-ensinando-programacao-para-criancas.html",
    },
    {
      name: "R7",
      img: R7,
      url: "https://www.youtube.com/watch?v=sRLl7_8R3VU",
    },
    {
      name: "Fantastico",
      img: Fantastico,
      url: "http://g1.globo.com/fantastico/quadros/reporter-por-um-dia/noticia/2014/08/max-lima-visita-escola-com-alunos-iguais-ao-vicente-de-geracao-brasil.html",
    },
    {
      name: "SBT",
      img: SBT,
      url: "https://www.youtube.com/watch?v=LrDBWNtFS0Y",
    },
    {
      name: "G1",
      img: G1,
      url: "https://g1.globo.com/pb/paraiba/noticia/colonias-de-ferias-em-joao-pessoa-tem-atividades-para-diferentes-idades.ghtml",
    },
    {
      name: "Band",
      img: Band,
      url: "https://www.youtube.com/watch?v=3isv8QeX41I",
    },
    {
      name: "OlharDigital",
      img: OlharDigital,
      url: "https://www.youtube.com/watch?v=ybqMW3Rrysc",
    },
    {
      name: "EncontroComFatima",
      img: Encontro,
      url: "https://www.youtube.com/watch?v=8_mWvKd5J3c",
    },
  ];

  const verifyPhoneNumber = useCallback(async (e) => {

    if (e && isValidPhoneNumber(e)) {

      const newNumber = e.replace('+', '')

      await axios.get(`https://sprinthub-api-master.sprinthub.app/whatsapp/checkexist/+${newNumber}?i=sgfranquias`).then(res => {
        setStatusPhoneWhatsapp(true)
      }).catch(err => {
        setStatusPhoneWhatsapp(false)
      })
    }
  }, []);

  useEffect(() => {

    // Quando a página terminar de carregar, depois de 1 segundo, selecionamos o país Brasil
    window.addEventListener('load', () => {
      setTimeout(() => {
        selectCountryRef.current.value = "Brazil";
        console.log("Brasil selecionado")
      }, 1000)
    }) 

  },[])

  return (
    <div className="container_landingpage_structure" id="detect_up_scroll">

      <Modal open={open} onClose={() => setOpen(false)} center>
        <iframe
          width="auto"
          height="auto"
          src="https://www.youtube.com/embed/0xyRcOSctBg"
          title="Conheça a SuperGeeks"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Modal>

      <>
        <div className="container_landingpage">
          <div className="content_landingpage_up">
            <div className="content_landingpage_up_left">
              <h1>Somos a 1ª e Melhor</h1>
              <h2>Rede do segmento</h2>
              <p>Venha mudar o mundo conosco. Seja um Franqueado SuperGeeks!</p>
              <div className="content_buttons_landingpage">

                <button
                  onClick={() => {
                    scroller.scrollTo("about_id_select", {
                      duration: 800,
                      delay: 0,
                      smooth: "easeInOutQuint",
                    });
                  }}
                >
                  Saiba Mais
                </button>


              </div>
            </div>
            <div
              className="content_landingpage_up_right"
              id="form_download_apresentation"
            >
              <div
                className="container_video_sg_landingpage"
                style={{ backgroundImage: "url(" + imgVideo + ")" }}
              >
                <button onClick={() => setOpen(true)}>
                  <BsFillPlayFill />
                </button>
              </div>
            </div>
          </div>
          <div className="content_landingpage_down"></div>
        </div>
      </>
      <div className="container_bottom_landingpage">
        <div className="container_download_apresentation">
          <div className="container_form_down_apresentation">
            <div className="title_form_down_apresentation">
              <h1>Receba a nossa apresentação</h1>
            </div>

            <form
              id="7_r5Y-sy0Ymup3kkLtg-p-form"
              autoComplete="off"
              role="form"
              method="post"
              encType="multipart/form-data"
              className="content_form_down_apresentation cust-7_r5Y-sy0Ymup3kkLtg-p"
              onSubmit={(event) => {
                setLoadingForm(true);
                // TODO: do something with form values
                console.log(event);
                setTimeout(() => {
                  navigate("/congrats");
                }, 5000);
              }}
            >
              <div className="mauticform-innerform">

                <input
                  id="Wx_QKaqTM5"
                  name="zZnGYTzuxO"
                  type="text"
                  className="mauticform-input cust-zZnGYTzuxO"
                  required
                  placeholder="Qual seu primeiro nome?*"
                  autoComplete="off"
                  onChange={(event) => setDataForm((prev) => ({ ...prev, name: event.target.value }))}
                />

                <input
                  id="SDy_9zdp2y"
                  name="FU5UnEl4y5"
                  type="text"
                  className="mauticform-input cust-FU5UnEl4y5"
                  placeholder="Qual o seu sobrenome?*"
                  required
                  onChange={(event) => setDataForm((prev) => ({ ...prev, lastName: event.target.value }))}
                />

                <input
                  id="eiS4hwnFxR"
                  name="pQ1r_McJ_8"
                  type="email"
                  className="mauticform-input cust-pQ1r_McJ_8"
                  placeholder="Qual o email principal?*"
                  required
                  onChange={(event) => setDataForm((prev) => ({ ...prev, email: event.target.value }))}
                />

                <div className="content_input_number_mautic_form">
                  <PhoneInput
                    countryCallingCodeEditable={true}
                    defaultCountry="BR"
                    placeholder="Qual o seu número/WhatsApp?*"
                    value={phoneCode}
                    onChange={async (e) => {
                      setPhoneCode(e || '')

                      await verifyPhoneNumber(e || '')
                    }}
                  />

                  {
                    phoneCode && isValidPhoneNumber(phoneCode) && (
                      <Tippy placement="top" theme="light" content={statusPhoneWhatsapp ? "Whatsapp válido." : "Telefone não possuí Whatsapp cadastrado."}>
                        <div className="contentIconCheck">
                          {
                            statusPhoneWhatsapp ?
                              <Lottie
                                options={defaultOptionsSuccess.current}
                                height={30}
                                width={30}
                                isClickToPauseDisabled={true}
                              /> : <Lottie
                                options={defaultOptionsIncorrect.current}
                                height={30}
                                width={30}
                                isClickToPauseDisabled={true}
                              />
                          }
                        </div>
                      </Tippy>
                    )
                  }


                </div>

                <input
                  id="8e2gakw8x1"
			            name="lG1wgtw3No"
                  type="hidden"
                  className="mauticform-input cust-Krv_R_38ek"
                  required
                  value={isValidPhoneNumber(phoneCode) ? `+${formatPhoneNumberIntl(phoneCode).replace(/\D/g, "")}` : ""}
                />

                <select
                  ref={selectCountryRef}
                  id="XGXLuJnBJc"
                  name="bOAqWatmtl"
                  className="mauticform-selectbox cust-bOAqWatmtl"
                  required
                  onChange={(event) => {
                    setDataForm((prev) => ({ ...prev, country: event.target.value, state: prev.country !== event.target.value ? null : prev.state, city: prev.country !== event.target.value ? null : prev.city }))
                  }}
                >
                  <option disabled value="">Selecione o seu país</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Åland Islands">Åland Islands</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="The Bahamas">The Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bonaire">Bonaire</option>
                  <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brasil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </option>
                  <option value="Virgin Islands (British)"
                  >Virgin Islands (British)</option>
                  <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">Central African Republic</option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Republic of the Congo">Republic of the Congo</option>
                  <option value="Democratic Republic of the Congo">
                    Democratic Republic of the Congo
                  </option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Curaçao">Curaçao</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands">Falkland Islands</option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern and Antarctic Lands">
                    French Southern and Antarctic Lands
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="The Gambia">The Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guernsey">Guernsey</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-Bissau">Guinea-Bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island and McDonald Islands">
                    Heard Island and McDonald Islands
                  </option>
                  <option value="Holy See">Holy See</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Ivory Coast">Ivory Coast</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Republic of Ireland">Republic of Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jersey">Jersey</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libya">Libya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macau">Macau</option>
                  <option value="Republic of Macedonia">Republic of Macedonia</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Federated States of Micronesia">
                    Federated States of Micronesia
                  </option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="North Korea">North Korea</option>
                  <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestine">Palestine</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn Islands">Pitcairn Islands</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Republic of Kosovo">Republic of Kosovo</option>
                  <option value="Réunion">Réunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russia</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Barthélemy">Saint Barthélemy</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Martin">Saint Martin</option>
                  <option value="Saint Pierre and Miquelon">
                    Saint Pierre and Miquelon
                  </option>
                  <option value="Saint Vincent and the Grenadines">
                    Saint Vincent and the Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="São Tomé and Príncipe">São Tomé and Príncipe</option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Sint Maarten">Sint Maarten</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia">South Georgia</option>
                  <option value="South Korea">South Korea</option>
                  <option value="South Sudan">South Sudan</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syria</option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Thailand">Thailand</option>
                  <option value="East Timor">East Timor</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">United Arab Emirates</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Wallis and Futuna">Wallis and Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>

                <select
                  id="VPeAx9Jh-g"
                  name="b4ilT_eovk"
                  className="mauticform-selectbox cust-b4ilT_eovk"
                  required
                  onChange={(event) => {
                    setDataForm((prev) => ({ ...prev, state: event.target.value, city: prev.state !== event.target.value ? null : prev.city }))
                  }}
                >
                  <option disabled value="">Selecione o seu estado</option>
                </select>

                <select
                  id="QSwWRTFYAM"
                  name="8VNYjnE15v"
                  className="mauticform-selectbox cust-8VNYjnE15v"
                  required
                  onChange={(event) => {
                    setDataForm((prev) => ({ ...prev, city: event.target.value }))
                  }}
                >
                  <option disabled value="">Selecione a sua cidade</option>
                </select>

                <select
                  id="Ztb4TByHPU"
                  name="ggL1IncayL"
                  className="form-select cust-ggL1IncayL"
                  required
                  onChange={(event) => {
                    setDataForm((prev) => ({ ...prev, investment: event.target.value }))
                  }}
                >
                  <option value="" disabled>
                    Qual sua capacidade de investimento?
                  </option>
                  <option value="50-100k">De 50 mil à 100 mil</option>
                  <option value="100-150k">De 100 mil à 150 mil</option>
                  <option value="150-200k">De 150 mil à 200 mil</option>
                  <option value="200-250k">De 200 mil à 250 mil</option>
                  <option value="acima250k">Acima de 250 mil</option>
                </select>


                <div className="content_submit_form_mautic">
                  <button
                    style={{
                      backgroundColor:
                        disableSubmit
                          ? "#CCC"
                          : loadingForm
                            ? "#FF0000"
                            : "",
                      pointerEvents:
                        disableSubmit ? "none" : "all",
                    }}
                    disabled={disableSubmit || loadingForm}
                    type="submit"
                    name="7_r5Y-sy0Ymup3kkLtg-p_submit"
                    id="7_r5Y-sy0Ymup3kkLtg-p_submit"
                    className="sprinthub-button btn btn-primary cust-1YarSMws2B"
                  >
                    {loadingForm ? (
                      <Lottie
                        options={defaultOptions.current}
                        height={50}
                        width={50}
                        isClickToPauseDisabled={true}
                      />
                    ) : (
                      "Enviar"
                    )}
                  </button>
                  <div className="sprinthub-lp-separator cust-e2i7asqdIV"></div>
                  <div
                    className="alert alert-success cust-_8R1ZhaeH7"
                    role="alert"
                    data-shubfsuccess="7_r5Y-sy0Ymup3kkLtg-p"
                  >
                    Formulário enviado com sucesso!
                  </div>
                </div>
              </div>


            </form>
          </div>
        </div>
        <div className="list_news_sg_landingpage">
          {imagesListNews.map((value, index) => {
            var styleList = {};
            var id = index + 1;
            switch (value.name) {
              case "Fantastico":
                styleList = { width: "75px", height: "auto" };
                break;
              case "EncontroComFatima":
                styleList = { width: "105px", height: "auto" };
                break;
              case "OlharDigital":
                styleList = { width: "135px", height: "auto" };
                break;
              case "Band":
                styleList = { width: "55px", height: "auto" };
                break;
              case "G1":
                styleList = { width: "60px", height: "auto" };
                break;
              case "folha":
                styleList = { width: "85px", height: "auto" };
                break;
              case "guia franquia":
                styleList = { height: "54px" };
                break;
              case "GradeEmpresas":
                styleList = { height: "44px" };
                break;
              case "R7":
                styleList = { height: "44px" };
                break;

              case "SBT":
                styleList = { height: "50px" };
                break;

              default:
                break;
            }
            return (
              <React.Fragment key={index}>
                <button
                  onClick={() =>
                    window.open(value.url, "_blank", "noopener,noreferrer")
                  }
                >
                  <img
                    style={styleList}
                    className={"item_list_news_sg_landingpage grid-" + id}
                    src={value.img}
                    alt="..."
                  />
                </button>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}
