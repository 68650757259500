import React from "react";
import "../assets/styles/parOfIt.css";

import { animated } from "react-spring";
import useBoop from "use-boop";
import { HiChevronDown } from "react-icons/hi";
import {
  scroller,
} from "react-scroll";
export default function PartOfIt({ setIsOpenModalForm }) {
  const [style, trigger] = useBoop({ y: 4 });
  return (
    <div className="container_parofit">
      <div className="content_parofit">
        <div className="item_parofit_left">
          <h1>FAÇA PARTE DESSA</h1>
          <h2>REDE DE SUCESSO!</h2>
          <p>Leve o ensino de Tecnologia, Inovação, Ciência da Computação e Empreendedorismo para a sua cidade.</p>
          <p>Baixe agora nossa apresentação.</p>
          <button
            onMouseEnter={trigger}
            onClick={() => {
              scroller.scrollTo("form_download_apresentation", {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuint",
              });
            }}
          >
            <span className="text_download_btn_parofit">Fazer download</span>
            <span className="icon_download_btn_parofit">
              <animated.span style={style}>
                <HiChevronDown size={25} />
              </animated.span>
            </span>
          </button>
        </div>
        <div className="item_parofit_right">
          <img src={`../../man_in_computer.png`} alt="..." />
        </div>
      </div>
    </div>
  );
}
