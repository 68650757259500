import React from "react";
import "../assets/styles/About.css";

export default function About() {
  return (
    <div className="container_about" id="about_id_select">
      <div className="content_about_left">
        <div className="content_title_about_left">
          <h1>SOBRE A</h1>
          <h2>SUPERGEEKS</h2>
        </div>
        <div className="content_about_left_description">
          <p>
            A SuperGeeks foi idealizada e criada pelo casal Marco Giroto e
            Vanessa Ban, entre 2012 e 2013, no Vale do Silício, Califórnia,
            Estados Unidos (polo mundial da tecnologia).{" "}
          </p>
          <p>
            Marco Giroto trabalhou em grandes empresas brasileiras e
            multinacionais, e criou sua primeira empresa de tecnologia aos 23
            anos. Vanessa Ban lecionou para crianças da pré-escola ao ensino
            médio e especializou-se em língua, literatura e semiótica.
          </p>
          <p>
            Com a união dos seus “superpoderes” criaram uma metodologia de
            ensino de Ciência da Computação infantojuvenil que levou mais de 1
            ano de pesquisa e desenvolvimento, para ser conluída em um modelo
            ideal para ensinar Ciência da Computação para crianças e
            adolescentes.
          </p>
          <h3>Marco Giroto e Vanessa Ban</h3>
          <h4>Fundadores da SuperGeeks</h4>
        </div>
      </div>
      <div className="content_about_right">
        <img src={`../../founders.png`} alt="..." />
      </div>
    </div>
  );
}
