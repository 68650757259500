import React from "react";
import "../assets/styles/container.css";

export default function Container(props) {

  var style = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    backgroundImage: "url(" + props.backgroundImage + ")",
    backgroundColor: props.backgroundColor,
    marginBottom: props.margin ? '0px' : ''
  }


  //props.left ? positionLeft
  return (
    <div className="container_general" style={style}>
      {props.children}
    </div>
  );
}
