import React from "react";
import "../assets/styles/sgnumbers.css";
import backgroundItem from "../assets/img/background_item_numbers.png";

export default function SGNumbers() {
  var list = [
    {
      name: "1ª",
      description:
        "Escola de Tecnologia, Inovação, Ciência da Computação e Empreendedorismo para crianças e adolescentes do Brasil.",
    },

    {
      name: "1ª",
      description: "Rede de franquias do setor de Programação e Robótica para KIDS & TEENS",
    },
    {
      name: "1ª",
      description: "Rede a ter um Sistema de Ensino 100% com foco em Escolas e padrões Internacionais, o CS Plus.",
    },
    {
      name: "+50",
      description: "Unidades físicas abertas no Brasil e na Europa.",
    },
    {
      name: "+50 mil",
      description: "Alunos alunos treinados pela SuperGeeks.",
    },
    {
      name: "+5 mil",
      description: "Horas de conteúdo, em diversos cursos.",
    },
  ];

  return (
    <div className="container_sgNumbers">
      <div className="sgNumbers_title">
        <h1>SUPERGEEKS</h1>
        <h2>EM NÚMEROS</h2>
      </div>

      <div className="content_sgNumbers">
        <ul className="list_itens_sgNumbers">
          {list.map((value, index) => {
            return (
              <li key={index} style={{ backgroundImage: "url(" + backgroundItem + ")" }}>
                <h1>{value.name}</h1>
                <p>{value.description}</p>
              </li>
            );
          })}
        </ul>

        <p className="description_sgNumbers">
          A SuperGeeks é a primeira escola e primeira rede do segmento de Tecnologia, Inovação, Programação, Robótica, Ciência da Computação e Empreendedorismo para Kids e Teens do Brasil e agora também com cursos para jovens e adultos. Somos a única rede com um conteúdo completo na área, com mais de 8 anos de cursos para crianças a partir dos 5 anos, até adultos. Além disso, somos a melhor do segmento, o que é COMPROVADO pelos diversos anos liderando o ranking de medalhistas da principal competição de computação do Brasil, a OBI.
        </p>
      </div>
    </div>
  );
}
