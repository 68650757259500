import React, { useState } from "react";
import "../assets/styles/model.css";

export default function Model() {
  let [activeItem, setACtiveItem] = useState(0);

  var listModel = [
    {
      name: "STANDARD",
      img: `../../heart.png`,
      list: {
        taxa: "35 mil",
        investimento: "200 mil",
        city:
          "Cidades acima de 250 mil habitantes ou bairro / regiões com médio poder aquisitivo",
        room: "A partir de 2 salas",
        mediaImovel: "120 m²",
        raioAtuacao: "A partir de 2km mediante análise de região",
        royalties: "Não cobra royalties",
        marketing: "2% sobre faturamento bruto",
        pointerEquilibrio: "6 a 18 meses",
        returnInevestimento: "de 28 a 36 meses",
        gainMid: "de 20% a 30%",
        timeContrato: "5 anos",
      },
    },
    {
      name: "SMALL",
      img: `../../heart_blue.png`,
      list: {
        taxa: "30 mil",
        investimento: "170 mil",
        city:
          "Cidades / regiões com médio a alto poder aquisitivo",
        room: "Até 2 salas",
        mediaImovel: "80 m²",
        raioAtuacao: "A partir de 2km mediante análise de região",
        royalties: "Não cobra royalties",
        marketing: "2% sobre faturamento bruto",
        pointerEquilibrio: "6 a 18 meses",
        returnInevestimento: "de 28 a 36 meses",
        gainMid: "de 25% a 35%",
        timeContrato: "5 anos",
      },
    },
    {
      name: "TINY",
      img: `../../ball.png`,
      list: {
        taxa: "25 mil",
        investimento: "150 mil",
        city: "Qualquer cidade ou Região mediante análise da franqueadora com preferência para cidades pequenas.",
        room: "A partir de 1 sala",
        mediaImovel: "50 m²",
        raioAtuacao: "A partir de 2km mediante análise de região",
        royalties: "Não cobra royalties",
        marketing: "2% sobre faturamento bruto",
        pointerEquilibrio: "6 a 12 meses",
        returnInevestimento: "de 24 a 36 meses",
        gainMid: "de 35% a 45%",
        timeContrato: "5 anos",
      },
    },
    {
      name: "SUPERCLASS",
      img: `../../box.png`,
      list: {
        taxa: "20 mil",
        investimento: "90 mil",
        city:
          "Qualquer cidade ou Região mediante aprovação da matriz, atuação em outro ponto comercial correlacionado",
        room: "A partir de 1 sala",
        mediaImovel: "50 m²",
        raioAtuacao: "A partir de 2km mediante análise de região",
        royalties: "Não cobra royalties",
        marketing: "2% sobre faturamento bruto",
        pointerEquilibrio: "6 a 12 meses",
        returnInevestimento: "de 24 a 36 meses",
        gainMid: "de 35% a 45%",
        timeContrato: "5 anos",
      },
    },
  ];

  return (
    <div className="container_model">
      <div className="content_title_model">
        <h1>MODELOS DE FRANQUIAS</h1>
        <h2>SUPERGEEKS</h2>
      </div>
      <div className="content_model_list">
        <div className="content_model_list_left">
          <div className="container_list_model_list_left">
            {listModel.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  
                  <button
                    onClick={() => setACtiveItem(index)}
                    onKeyDown={() => {}}
                    className={
                      activeItem === index
                        ? "item_list_active"
                        : "item_list_inactive"
                    }
                    key={index}
                  >
              
                    {value.name}
                  </button>
                </React.Fragment>
              );
            })}
          </div>
          {/*
            <div className="content_obs_model_list">
            <p>
              Os índices médios estimados acima dependem de diversos fatores
              como região, investimento em marketing, empenho da equipe e
              fraqueado, entre outros fatores;
            </p>
            <p>
              A lucratividade média está diretamente relacionada as despesas e
              receitas. Considera-se uma boa lucratividade média quando a
              unidade atingir ao menos o dobro do número de alunos necessário
              para o ponto de equilíbrio.
            </p>
          </div>
          */}
        </div>

        <div className="content_model_list_right">
          {listModel.map((value, index) => {
            if (index === activeItem) {
              var list = listModel[activeItem].list;

              return (
                <React.Fragment key={index}>
                  <div className="item_list_model_false">
                    <h1>Taxa de Franquia</h1>
                    <span>{list.taxa}</span>
                  </div>

                  <div className="item_list_model_true">
                    <h1>Investimento médio</h1>
                    <span>{list.investimento}</span>
                  </div>

                  <div className="item_list_model_false">
                    <h1>Cidade / Região</h1>
                    <span>{list.city}</span>
                  </div>

                  <div className="item_list_model_true">
                    <h1>Sala de aula</h1>
                    <span>{list.room}</span>
                  </div>

                  <div className="item_list_model_false">
                    <h1>Metragem média do imóvel</h1>
                    <span>{list.mediaImovel}</span>
                  </div>

                  <div className="item_list_model_true">
                    <h1>Raio de atuação</h1>
                    <span>{list.raioAtuacao}</span>
                  </div>

                  <div className="item_list_model_false">
                    <h1>Royalties</h1>
                    <span>{list.royalties}</span>
                  </div>

                  <div className="item_list_model_true">
                    <h1>Fundo de marketing</h1>
                    <span>{list.marketing}</span>
                  </div>

                  <div className="item_list_model_false">
                    <h1>Ponto de equilibrio</h1>
                    <span>{list.pointerEquilibrio}</span>
                  </div>

                  <div className="item_list_model_true">
                    <h1>Retorno do investimento</h1>
                    <span>{list.returnInevestimento}</span>
                  </div>

                  <div className="item_list_model_false">
                    <h1>Lucratividade média</h1>
                    <span>{list.gainMid}</span>
                  </div>

                  <div className="item_list_model_true">
                    <h1>Tempo de contrato</h1>
                    <span>{list.timeContrato}</span>
                  </div>
                </React.Fragment>
              );
            }

            return "";
          })}
        </div>
        <div className="content_obs_model_list">
          <p>
            Os índices médios estimados acima dependem de diversos fatores como
            região, investimento em marketing, empenho da equipe do fraqueado,
            entre outros fatores;
          </p>
          <p>
            A lucratividade média está diretamente relacionada as despesas e
            receitas. Considera-se uma boa lucratividade média quando a unidade
            atingir ao menos o dobro do número de alunos necessário para o ponto
            de equilíbrio.
          </p>
        </div>
      </div>
    </div>
  );
}
