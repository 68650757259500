import React, { useEffect, useState } from "react";
import "../assets/styles/fonts.css";
import "../assets/styles/index.css";

//Libs
import { BiChevronUp } from "react-icons/bi";
import { scroller } from "react-scroll";
import CookieConsent, {
  Cookies,
} from "react-cookie-consent";


//Components
import Container from "../components/Container.jsx";
import Navbar from "../components/Navbar.jsx";
import LandingPage from "../components/LandingPage.jsx";
import About from "../components/About.jsx";
import Model from "../components/Model.jsx";
import ChangeYourLife from "../components/ChangeYourLife.jsx";
import SGNumbers from "../components/SGNumbers.jsx";
//import Benefits from "../components/Benefits.jsx";
import PartOfIt from "../components/PartOfIt.jsx";
import Footer from "../components/Footer.jsx";
import Seo from "../components/SEO.jsx";
import { FormDownloadApresentationModal } from "../components/FormDownloadApresentationModal";

// Images
import ImgBackground from "../assets/img/background.png";
import Logo from "../assets/img/logo.png";
import "../assets/styles/reset.css";

export default function Index() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [navFix, setNavFix] = useState(false);
  const [isOpenModalForm, setIsOpenModalForm] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    if (scrollPosition > 50) {
      //console.log("fixou navbar");
      setNavFix(true);
    } else {
      //console.log("desfixou navbar");
      setNavFix(false);
    }
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
  });

  useEffect(() => {
    console.log("Cookie: ", Cookies.get("CookieConsent"));
  }, [Cookies.get("teste")]);

  var stylesCookies = {};

  return (
    <React.Fragment>

      <Seo />
      <Container backgroundImage={ImgBackground} left>
        <Navbar navFix={navFix} imgLogo={Logo} />
        <LandingPage navFix={navFix} isOpenModalForm={isOpenModalForm} setIsOpenModalForm={setIsOpenModalForm} />
      </Container>

      <Container>
        <About />
      </Container>

      <Container>
        <Model />
      </Container>

      <Container>
        <ChangeYourLife isOpenModalForm={isOpenModalForm} setIsOpenModalForm={setIsOpenModalForm} />
      </Container>

      <Container>
        <SGNumbers />
      </Container>

      <Container margin={true}>
        <PartOfIt isOpenModalForm={isOpenModalForm} setIsOpenModalForm={setIsOpenModalForm} />
      </Container>

      <Container margin={true}>
        <Footer Logo={Logo} />
      </Container>

      {scrollPosition >= 40 && (
        <button
          className="btn_up_scroll"
          onClick={() => {
            scroller.scrollTo("detect_up_scroll", {
              duration: 800,
              delay: 0,
              smooth: "easeInOutQuint",
            });
          }}
        >
          <BiChevronUp size={40} />
        </button>
      )}

      {Cookies.get("CookieConsent") === false ||
        (Cookies.get("CookieConsent") === undefined && (
          <CookieConsent
            location="bottom"
            buttonText="Eu aceito"
            cookieName="CookieConsent"
            style={stylesCookies}
            containerClasses="container_cookie_consent"
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={365}
            desativarButtonStyles={true}
            onAccept={() => { }}
            onDecline={() => {
              document.cookie.split(";").forEach(function (c) {
                document.cookie = c
                  .replace(/^ +/, "")
                  .replace(
                    /=.*/,
                    "=;expires=" + new Date().toUTCString() + ";path=/"
                  );
              });
            }}
          >
            <p>
              Ao acessar o nosso site, você concorda com o uso de cookies para
              analisar e produzir conteúdo e anúncios adaptados aos seus
              interesses. Leia a nossa <a ta href="https://2021.supergeeks.com.br/politica-de-privacidade/" alt="Politica de privacidade" target="_blank">Política de Privacidade</a>
            </p>
          </CookieConsent>
        ))}

        <FormDownloadApresentationModal isOpen={isOpenModalForm} onRequestClose={() => {
          setIsOpenModalForm(false);
        }} />
    </React.Fragment>
  );
}
