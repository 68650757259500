import Modal from 'react-modal';
import { GrFormClose } from 'react-icons/gr';

import './../assets/styles/formDownloadApresentationModal.css'
import React,{ memo } from "react";

export const FormDownloadApresentationModal = memo(({ isOpen, onRequestClose }) => {
  
    return(
        <>
            <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                content: {
                    maxWidth: '30rem',
                    height: '850px',
                    margin: '0 auto',
                    padding: '0',
                    overflow: "initial"
                }
            }}
            > 
                <main className="container_modal">
                    <header className="header_modal">
                        <h5>Preencha o formulário</h5>
                        <button onClick={onRequestClose}>
                            <GrFormClose  color="#FFF" />
                        </button>
                    </header>

                    <div className="content_modal">
                        <iframe src="https://sgfranquias.sprinthub.site/candidato-franquia" />
                    </div>
                </main>
            </Modal>
        </>
    )
})