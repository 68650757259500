import React from "react";
import "../assets/styles/navbar.css";

export default function Navbar(props) {
  return (
    <div style={{backgroundColor: props.navFix ? '#FFF' : 'transparent'}} className="container_navbar" >
      {props.imgLogo && (
        <img style={{width: props.navFix ? '150px' : ''}} className="item_img_logo" src={props.imgLogo} alt="..." />
      )}
    </div>
  );
}
